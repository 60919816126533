import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import StateContext from "../../contexts/ContextProvider";
import axios from "axios";
import { useEffect } from "react";
import Pincode from "react-pincode";
import "../view.css";
import blank from "../../assets/images (1).png";
import { BsFillPencilFill } from "react-icons/bs";
import { ImCross } from "react-icons/im";

//Alert
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";

const AddManageItem = () => {
  const navigate = useNavigate();

  const mainPage = () => {
    navigate("/manageitem");
  };

  const { merchantId } = useContext(StateContext);
  const { token } = useContext(StateContext);
  const { viewData } = useContext(StateContext);
  const { setViewData } = useContext(StateContext);
  const { setValue } = useContext(StateContext);

  const { orbitMartCommission } = useContext(StateContext);

  const [alert, setAlert] = useState(false);
  const [pincodeData, setPincodeData] = useState([]);

  const [data, setData] = useState([""]);
  const [newValue, setNewValue] = useState([""]);

  const [domainTypes, setDomainTypes] = useState([""]);
  const [category, setCategory] = useState([""]);
  const [classification, setClassification] = useState([""]);
  const [subClassification, setSubClassification] = useState([""]);
  const [groupCategory, setGroupCategory] = useState([""]);
  const [brandName, setBrandName] = useState([""]);
  const [unitQuantitys, setUnitQuantitys] = useState([""]);

  const [discountTypeSelect, setDiscountTypeSelect] = useState([""]);
  const [actualPrice, setActualPrice] = useState();
  const [customerPrice, setCustomerPrice] = useState();
  const [merchantPrice, setMerchantPrice] = useState();

  function fetchDomainTypes() {
    const id = merchantId;
    axios
      .get(`https://api.orbitmart.co.in/api/v1/merchant/Type`)
      .then((res) => {
        setDomainTypes(res?.data?.data);
      })
      .catch((err) => {});
  }
  function fetchCategories(domainId) {
    const id = merchantId;
    axios
      .get(`https://api.orbitmart.co.in/api/v1/category/bymerchantTypeId/${domainId}`)
      .then((res) => {
        setCategory(res.data.data);
        fetchClassifications();
      })
      .catch((err) => {});
  }

  function fetchClassifications(id) {
    // const catid = data?.categoryId;
    // console.log(data?.categoryId,"adsfdfwfdb")
    // const id = data?.categoryId
    // const id = catid;
    axios
      .get(`https://api.orbitmart.co.in/api/v1/classification/bycategoryId/${id}`)
      .then((res) => {
        setClassification(res.data.data);
      })
      .catch((err) => {});
  }

  function fetchSubClassifications(id) {
    // const classId = data.classificationId;
    // const id = classId;
    axios
      .get(
        `https://api.orbitmart.co.in/api/v1/subclassification/byclassificationId/${id}`
      )
      .then((res) => {
        setSubClassification(res.data.data);
      })
      .catch((err) => {});
  }

  function fetchGroupCategory() {
    axios
      .get(`https://api.orbitmart.co.in/api/v1/groupcategory`)
      .then((res) => {
        setGroupCategory(res.data.data);
      })
      .catch((err) => {});
  }

  function fetchBrandName() {
    axios
      .get(`https://api.orbitmart.co.in/api/v1/brandname`)
      .then((res) => {
        setBrandName(res.data.data);
      })
      .catch((err) => {});
  }

  function fetchUnitQuantity() {
    axios
      .get(`https://api.orbitmart.co.in/api/v1/unitQuantity`)
      .then((res) => {
        setUnitQuantitys(res.data.data);
      })
      .catch((err) => {});
  }

  useEffect(()=>     fetchDomainTypes(),[])
  
  useEffect(() => {
    itemPriceFinal();
    customerPriceFinal();
    merchantPriceFinal();
    // fetchDomainTypes();
    fetchCategories();
    fetchGroupCategory();
    fetchBrandName();
    fetchUnitQuantity();
  }, [data, discountTypeSelect, data.itemPrice]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleChangedomainType = (e) => {
    const domainId = e.target.value; // Get the selected domain type ID
    setData({ ...data, domainType: domainId }); // Set the selected domain ID to state
    if (domainId) {
      fetchCategories(domainId); // Fetch categories when domain type is selected
    }
  };

  const [selectedImages, setSelectedImages] = useState([]);

  const onSelectFile = (event) => {
    // const selectedFiles = event.target.files;
    const selectedFiles = event.target.files[0];
    // const selectedFilesArray = Array.from(selectedFiles);

    // const imagesArray = selectedFilesArray.map((file) => {
    //   return file;
    // });

    setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    // setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  const [image, setImage] = React.useState("");
  // const [imgUpload, setImgUpload] = useState(false);
  const [serverImage, setServerImage] = useState([""]);

  function deleteImage(e) {
    const s = image.filter((item, index) => index != e);
    setImage(s);
  }

  function deleteImage(e) {
    const s = image.filter((item, index) => index != e);
    setImage(s);
  }

  function imageUpload() {
    debugger;
    const uploadAPI = "https://api.orbitmart.co.in/api/v1/upload";
    const formData = new FormData();
    formData.append("Image", image);
    axios
      .post(uploadAPI, formData)
      .then((res) => {
        setServerImage(res.data);
      })
      .catch((error) => {});
  }

  const ImageThumb = ({ image }) => {
    return (
      <div>
        <img src={URL.createObjectURL(image)} alt={image.name} />
      </div>
    );
  };

  function itemPriceFinal() {
    // debugger;
    if (data.itemPrice != null) {
      const newValue1 =
        data.itemPrice - (orbitMartCommission / 100) * data.itemPrice;
      if (data.gst == null || discountTypeSelect == null) {
        setActualPrice(newValue1);
      } else if (data.gst != null) {
        const newValue2 = newValue1 + (data.gst / 100) * newValue1;
        setActualPrice(newValue2);
        if (discountTypeSelect === "PERCENT") {
          if (data.discount != null) {
            const newValue3 = newValue2 - newValue2 * (data.discount / 100);
            setActualPrice(newValue3);
          }
        } else if (discountTypeSelect === "FIXED") {
          const newValue3 = newValue2 - data.discount;
          setActualPrice(newValue3);
        } else {
          setActualPrice(newValue2);
        }
      }
    }
  }

  function customerPriceFinal() {
    setCustomerPrice(data.itemPrice);
    if (data.itemPrice != null) {
      if (discountTypeSelect === "FIXED" && data.discount != null) {
        const newValue1 = data.itemPrice - data.discount;
        const newValue2 = newValue1 + (data.gst / 100) * newValue1;
        setCustomerPrice(newValue2);
      } else if (
        discountTypeSelect === "PERCENT" &&
        data.discount != null &&
        data.gst != null
      ) {
        const newValue1 =
          data.itemPrice - data.itemPrice * (data.discount / 100);
        const newValue2 = newValue1 + (data.gst / 100) * newValue1;
        setCustomerPrice(newValue2);
      }
    }
  }

  function merchantPriceFinal() {
    if (data.itemPrice != null) {
      const newValue1 =
        data.itemPrice - (orbitMartCommission / 100) * data.itemPrice;
      setMerchantPrice(newValue1);
      if (discountTypeSelect === "FIXED") {
        const newValue2 = newValue1 - data.discount;
        const newValue3 = newValue2 + (data.gst / 100) * newValue2;
        setMerchantPrice(newValue3);
      } else if (discountTypeSelect === "PERCENT") {
        const newValue4 = data.discount / 100;
        const newValue2 = newValue1 - newValue1 * newValue4;
        const newValue3 = newValue2 + (data.gst / 100) * newValue2;
        setMerchantPrice(newValue3);
      } else if (data.gst != null) {
        const newValue2 = (data.gst / 100) * newValue1;
        const newValue3 = newValue2 + newValue1;
        setMerchantPrice(newValue3);
      }
    }
  }

  const [error, setError] = useState(false);
  const [error1, setError1] = useState(false);
  async function postData() {
    debugger;
    const finalToken = token;
    const uploadImage = "https://api.orbitmart.co.in/api/v1/upload";
    const uploadData = "https://api.orbitmart.co.in/api/v1/product";
    const formData = new FormData();
    formData.append("Image", selectedImages[0]);
    axios
      .post(uploadImage, formData)
      .then((res) => {
        debugger;
        const newimg = res.data.files;
        const coverPhoto = newimg[0];
        const finalData = {
          merchantTypeId: merchantId,
          domainType: data.domainType,
          categoryId: data.categoryId,
          groupCategoryId: data.groupCategoryId,
          classificationId: data.classificationId,
          subClassificationId: data.subClassificationId,
          brandNameId: data.brandNameId,
          unitQuantityId: data.unitQuantity,
          unitPerQuantity: data.unitPerQuantity,
          itemName: data.itemName,
          netWeight: data.netWeight,
          discountType: discountTypeSelect,
          customerDiscount: data.discount,
          gst: data.gst,
          orbitMartCommission: orbitMartCommission,
          itemPrice: data.itemPrice,
          merchantPrice: merchantPrice,
          customerPrice: customerPrice,
          description: data.description,
          stockQuantity: data.stockQuantity,
          bestOfferItem: true,
          image: coverPhoto,
        };
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-token": finalToken,
          },
          body: JSON.stringify(finalData),
        };
        fetch(uploadData, requestOptions).then((res) => {
          if (res.status == 201) {
            setAlert(true);
            setTimeout(() => {
              mainPage();
              setAlert(false);
            }, 1000);
          } else {
            setError(true);
            setTimeout(() => {
              // mainPage();
              setError(false);
            }, 1000);
          }
        });
      })
      .catch((err) => {
        setTimeout(() => {
          mainPage();
          // setError(true);
        }, 1000);
      });
  }

  useEffect(()=> fetchClassifications(data?.categoryId),[data?.categoryId])
  useEffect(()=> fetchSubClassifications(data?.classificationId),[data?.classificationId])



  const filteredDomainType = domainTypes?.map(domain=> domain)
  console.log(data?.categoryId,"filteredDomainType")
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <h4 class="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
        Add Item
      </h4>
      <p class="peer-invalid:visible text-red-700 font-light">
        Please Enter All the * (required) Fields
      </p>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          onClick={() => mainPage()}
          style={{ width: "auto", padding: "7px 14px"}}
          class="backButton"
        >
          Back
        </button>
      </div>
      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <div className="flex">
              <label>Domain Type</label>
              {data.domainType ? null : (
                <p class="peer-invalid:visible text-red-700 font-light"> *</p>
              )}
            </div>
            <select
              class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="domainType"
              value={data?.domainType}
              onChange={handleChangedomainType}
            >
              <option value="" disabled selected>
                Select Value
              </option>
              {
  filteredDomainType?.map(domain => (
    <option key={domain?._id} value={domain?._id}>
      {domain?.domainType}
    </option>
  ))
}
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <div className="flex">
              <label>Category</label>
              {data.categoryId ? null : (
                <p class="peer-invalid:visible text-red-700 font-light"> *</p>
              )}
            </div>
            <select
              class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="categoryId"
              value={data.categoryId}
              onChange={handleChange}
              required
            >
              <option value="" disabled selected>
                Select Value
              </option>
              {category.map((typeget) => (
                <option value={typeget._id} id={typeget._id}>
                  {typeget.title}
                </option>
              ))}
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <div className="flex">
              <label>Group Category</label>
              {data.groupCategoryId ? null : (
                <p class="peer-invalid:visible text-red-700 font-light"> *</p>
              )}
            </div>
            <select
              class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="groupCategoryId"
              value={data.groupCategoryId}
              onChange={handleChange}
              required
            >
              <option value="" disabled selected>
                Select Value
              </option>
              {groupCategory.map((typeget) => (
                <option value={typeget._id} id={typeget._id}>
                  {typeget.title}
                </option>
              ))}
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <div className="flex">
              <label>Classification</label>
              {data.classificationId ? null : (
                <p class="peer-invalid:visible text-red-700 font-light"> *</p>
              )}
            </div>
            <select
              class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="classificationId"
              value={data.classificationId}
              onChange={handleChange}
              required
            >
              <option value="" disabled selected>
                Select Value
              </option>
              {classification.map((typeget) => (
                <option value={typeget._id} id={typeget._id}>
                  {typeget.title}
                </option>
              ))}
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <div className="flex">
              <label>Sub Classification</label>
              {data.subClassificationId ? null : (
                <p class="peer-invalid:visible text-red-700 font-light"> *</p>
              )}
            </div>
            <select
              class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="subClassificationId"
              value={data.subClassificationId}
              onChange={handleChange}
              required
            >
              <option value="" disabled selected>
                Select Value
              </option>
              {subClassification.map((typeget) => (
                <option value={typeget._id} id={typeget._id}>
                  {typeget.title}
                </option>
              ))}
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <div className="flex">
              <label>Brand Name</label>
              {data.brandNameId ? null : (
                <p class="peer-invalid:visible text-red-700 font-light"> *</p>
              )}
            </div>
            <select
              class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="brandNameId"
              value={data.brandNameId}
              onChange={handleChange}
              required
            >
              <option value="" disabled selected>
                Select Value
              </option>
              {brandName.map((typeget) => (
                <option value={typeget._id} id={typeget._id}>
                  {typeget.title}
                </option>
              ))}
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <div className="flex">
              <label>Item Name</label>
              {data.itemName ? null : (
                <p class="peer-invalid:visible text-red-700 font-light"> *</p>
              )}
            </div>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="itemName"
              value={data.itemName}
              onChange={handleChange}
              placeholder="Enter Item Name"
              required
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Unit Quantity</label>
            <select
              class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="unitQuantity"
              value={data.unitQuantity}
              onChange={handleChange}
              required
            >
              <option selected>Select Value</option>
              {unitQuantitys.map((typeget) => (
                <option value={typeget._id} id={typeget._id}>
                  {typeget.title}
                </option>
              ))}
            </select>
            {data.unitQuantity ? null : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter this Field
              </p>
            )}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="unitPerQuantity"
              class="form-label inline-block  text-gray-700"
            >
              Unit Per Quantity
            </label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="unitPerQuantity"
              value={data.unitPerQuantity}
              onChange={handleChange}
              placeholder="Enter Quantity"
            />
          </div>

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Net Weight (in GM)</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="netWeight"
              name="netWeight"
              value={data.netWeight}
              onChange={handleChange}
              placeholder="Weight of item in Gram"
              required
            />
            {data.netWeight ? null : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter this Field
              </p>
            )}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Unit Per Quantity</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="unitPerQuantity"
              value={data.unitPerQuantity}
              onChange={handleChange}
              placeholder="Enter Unit Per Quantity"
              required
            />
            {data.unitPerQuantity ? null : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter this Field
              </p>
            )}
          </div> */}

          <div class="relative z-0 p-2 w-full mb-6 group">
            <div className="flex">
              <label>Price</label>
              {data.itemPrice ? null : (
                <p class="peer-invalid:visible text-red-700 font-light"> *</p>
              )}
            </div>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="itemPrice"
              name="itemPrice"
              value={data.itemPrice}
              min="0"
              onChange={handleChange}
              placeholder="Enter Price"
              required
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <div className="flex">
              <label>Discount Type</label>
              {discountTypeSelect[0] !== "" ? null : (
                <p class="peer-invalid:visible text-red-700 font-light"> *</p>
              )}
            </div>
            <select
              name="discountType"
              value={discountTypeSelect}
              onChange={(e) => setDiscountTypeSelect(e.target.value)}
              class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              required
            >
              <option value="" disabled selected>
                Select Value
              </option>
              <option value="PERCENT">Percentage</option>
              <option value="FIXED">Fixed</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <div className="flex">
              <label>Discount (in % or Fix Value)</label>
              {data.discount ? null : (
                <p class="peer-invalid:visible text-red-700 font-light"> *</p>
              )}
            </div>
            <input
              type="number"
              class="
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        -webkit-appearance: none;
        margin: 0;
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="discount"
              value={data.discount}
              min="0"
              onChange={handleChange}
              placeholder="Customer Discount"
              required
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <div className="flex">
              <label>GST (in %)</label>
              {data.gst ? null : (
                <p class="peer-invalid:visible text-red-700 font-light"> *</p>
              )}
            </div>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="gst"
              name="gst"
              value={data.gst}
              min="0"
              max="28"
              onChange={handleChange}
              placeholder="Enter GST %"
              required
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Orbit Mart Commision</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="orbit"
              name="orbitMartCommission"
              value={orbitMartCommission}
              onChange={handleChange}
              placeholder="Enter Orbit Mart Commission"
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Customer Price</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="customerprice"
              name="customerprice"
              value={Math.round(customerPrice)}
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Merchant Price</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="merchantprice"
              name="merchantprice"
              value={Math.round(merchantPrice)}
              disabled
            />
          </div>

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Actual Price</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="actualSellingPrice"
              name="actualSellingPrice"
              value={actualPrice}
              disabled
            />
          </div> */}

          <div class="relative z-0 p-2 w-full mb-6 group">
            <div className="flex">
              <label>Stock Quantity</label>
              {data.stockQuantity ? null : (
                <p class="peer-invalid:visible text-red-700 font-light"> *</p>
              )}
            </div>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="stockQuantity"
              value={data.stockQuantity}
              onChange={handleChange}
              placeholder="Available Stock"
              required
            />
          </div>

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="customerDiscount"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Customer Discount
            </label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="customerDiscount"
              value={data.customerDiscount}
              onChange={handleChange}
              placeholder="Enter Customer Discount"
            />
          </div> */}

          <div class="relative z-0 p-2 w-full mb-6 group">
            <div className="flex">
              {" "}
              <label>Description</label>
              {data.description ? null : (
                <p class="peer-invalid:visible text-red-700 font-light"> *</p>
              )}
            </div>
            <textarea
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="description"
              name="description"
              value={data.description}
              onChange={handleChange}
              placeholder="Description of Current Product"
              required
            />
          </div>

          <div>
            {/* <label>Image</label> */}
            <div
            // class="relative mt-1 flex justify-center w-40"
            // className="flex flex-row "
            >
              {" "}
              <div
                // class=" absolute right-0 top-0 z-10 flex"
                className="flex   items-center"
              >
                <label className="flex pl-3 pr-1">Image</label>
                {selectedImages.length ? null : (
                  <p class="peer-invalid:visible text-red-700 font-light"> *</p>
                )}
                <label
                  for="file-upload"
                  style={{paddingLeft: '15px'}}
                  className="flex cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none"
                >
                  <BsFillPencilFill />
                  <input
                    id="file-upload"
                    name="file-upload"
                    disabled={selectedImages.length == 1}
                    accept="image/*"
                    type="file"
                    onChange={onSelectFile}
                    class="sr-only"
                  />
                </label>
              </div>
              <div class="relative z-0 p-2 w-full mb-6 group">
                {selectedImages.length > 0 ? (
                  selectedImages.map((item, index) => {
                    return (
                      <div style={{ display: "inline-block"}} key={item}>
                        <p className="block-icon rounded">
                          <img style={{ width: '100px', height: '100px', objectFit: 'cover'}} src={URL.createObjectURL(item)} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                            className="icon-belowtag"
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img style={{width: '100px', height: '100px'}} src={blank} alt="user-profile" />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <button type="button" class="addButton" onClick={imageUpload}>
              Image Upload
            </button>
          </div> */}
        </div>
      </form>
      <div class="flex space-x-2 justify-end">
        {alert ? (
          <Stack spacing={2}>
            <Alert variant="filled" severity="success">
              Offer Added Successfully
            </Alert>
          </Stack>
        ) : null}
        {error ? (
          <Stack spacing={2}>
            <Alert variant="filled" severity="error">
              Sorry, Data Cannot be Added at this moment. Please try Again!
            </Alert>
          </Stack>
        ) : null}
        {error1 ? (
          <Stack spacing={2}>
            <Alert variant="filled" severity="error">
              Please, Fill All The Fields
            </Alert>
          </Stack>
        ) : null}
        <button
          type="button"
          class="addButton"
          onClick={postData}
          style={{ width: "auto", padding: "7px 14px"}}
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default AddManageItem;